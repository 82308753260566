:root {
  --mdc-theme-primary: #d58000 !important;
  --mdc-theme-on-primary: #fff !important;
  --mdc-theme-secondary: #4c563b !important;
  --mdc-theme-on-secondary: #fff !important;
  --mdc-theme-surface: #fff !important;
  --mdc-theme-on-surface: 0, 0, 0 !important;
  --mdc-theme-background: #f6f7f9 !important;
  --mdc-theme-app-bar: #fff !important;
  --mdc-theme-on-app-bar: #000 !important;
}

body {
  font-size: 16px;
  /*  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
p {
  margin: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cart-container {
  border-radius: 25px;
  display: inline-flex;
  position: relative;
  height: 43px;
  width: 230px;
  background-color: rgba(0, 0, 0, 0.05);
}
.md-btn {
  border-radius: 25px !important;
}
/* Shopping Cart */
.cart-icon {
  display: inline-flex;
  position: absolute;
  top: 6px;
  left: 10px;
}
.cart-button {
  display: inline-flex;
  position: absolute;
  top: 4px;
  right: 4px;
}
.cart-header {
  margin-left: 5px;
  left: 40px;
  right: auto;
  top: 5px;
  font-size: 12px;

  color: #727272;
  position: absolute;
  cursor: pointer;
}
/* Cart */
.price > .currency {
  line-height: 1;
  vertical-align: top;
  font-size: 20px;
}
.price > .unit {
  font-size: 36px;
  position: relative;
  top: -13px;
}
.price > .cents {
  font-size: 20px;
  line-height: 1;
  vertical-align: top;
}
/* Product Card */
.card-button {
  display: inline-flex;
  position: relative !important;
  height: 35px;
  width: 118px;
  border-radius: 25px !important;
}

.md-btn.card-button-right {
  border-radius: 1px 20px 20px 1px !important;
  min-width: 10px !important;
  padding: 2px 10px;
}
.md-btn.card-button-left {
  border-radius: 20px 1px 1px 20px !important;
  min-width: 10px !important;
  padding: 2px 10px;
}
.card-button-center {
  width: 70px;
  padding: 7px 0px;
}
.md-icon-close {
  position: absolute;
  right: 1px;
  top: 1px;
}
.cart-footer {
  position: fixed;
  bottom: 0;
  margin-left: 0;
  z-index: 1000;
  width: 350px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cart-footer .checkout {
  margin-top: 6px;
  display: flex;
}

.cart-footer .checkout .md-btn {
  margin-top: 6px;
}
.checkout > div.uk-grid-small {
  width: 340px;
}
@media (max-width: 960px) {
  .checkout > div.uk-grid-small {
    width: 260px;
  }
}
.cart-message-icon {
  position: relative;
}
.cart-message-icon span {
  top: -6px;
}
.uk-accordion > li.uk-open {
  margin: 0 !important;
}
.md-input-wrapper.md-input-wrapper-outlined.md-input-dense > input,
.md-input-wrapper.md-input-wrapper-outlined.md-input-dense > select {
  padding: 12px 12px 12px !important;
}
.touch-cursor {
  cursor: pointer;
}
.input_search {
  min-width: 400px;
}
.logo {
  min-height: 80px;
  padding: 0 20px 0px 20px;
}
img.shadow {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.main_logo_store_mobile {
  width: 50px;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
.main_logo_store_mobile.big_logo {
  width: 90px;
  position: relative;
  top: 25px;
  padding-top: 15px;
}
.main_logo_store {
  width: 50px;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
.main_logo_store.big_logo {
  width: 140px;
  position: relative;
  top: 35px;
  padding-top: 15px;
}
.custom-menu > li {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.custom-menu > li:hover {
  background-color: var(--mdc-theme-primary);
  border-radius: 25px;
}
/* .custom-menu > li:hover {
  transform: translate(0, -4px);
}
.custom-menu > li > a.active {
  transform: translate(0, -4px);
}
.custom-menu > li a::before {
  content: "";
  position: absolute;
  width: 100%;
  text-align: center;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: var(--mdc-theme-primary);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.custom-menu > li:hover a:not(.menu-item-icon):before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.custom-menu > li > a.active::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
} */
.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: inset 0 0 4px rgba(26, 35, 45, 0.1);
  /*border: 5px solid #1b4de2; */
  text-align: center;
  display: inline-block;
}
.numberCircle.numberCircle-default {
  background: #e2e8f0 !important;
  color: #000;
}
.numberCircle > span {
  top: 6;
  position: relative;
  font-size: 18px;
  font-weight: 600;
}
.rgb-white-gradient-reverse {
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.inline-content {
  display: flex;
  flex-direction: column-reverse;
}
.inline-content > .inline-right {
  margin: 0 48px;
}

.inline-content-left,
.inline-content-right {
  position: relative;
}
.inline-content-left {
  top: -30px;
}
.inline-content-right {
  top: 30px;
}
@media (min-width: 960px) {
  .inline-content > .inline-right {
    width: 50%;
    padding: 32px;
  }
  .inline-content {
    flex-direction: row;
  }
  .inline-content-left {
    left: 40px;
    top: 0;
  }
  .inline-content-right {
    left: -50px;
    top: 30px;
  }
}

.header-shop.uk-navbar-nav > li:not(.menu-active) > a {
  color: #fff !important;
}
.header-shop.uk-navbar-nav > li > a {
  color: #fff !important;
}
.header-shop.uk-navbar-nav > li:hover {
  background-color: black !important;
}
.menu-active {
  background-color: black !important;
}
.menu-active > a {
  /* color: var(--mdc-theme-primary) !important; */
}
.md-input-wrapper.md-input-wrapper-outlined.md-input-dense > input,
.md-input-wrapper.md-input-wrapper-outlined.md-input-dense > select {
  padding: 10px 12px !important;
}
.pointer {
  cursor: pointer;
}
.delivery-cat {
  padding: 8px;
  background-color: #727272;
}
.uk-card.selected {
  background-color: var(--mdc-theme-primary);
}
.uk-card.selected h5,
.uk-card.selected i {
  color: var(--mdc-theme-on-primary);
}
.icon-fontawesome {
  width: auto !important;
  /* font-size: 1.6rem !important; */
  font-family: "Font Awesome 5 Brands" !important;
}
