/*@font-face {
  font-family: "Google Sans";
  src: url("GoogleSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("GoogleSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Google Sans";
  src: url("GoogleSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("GoogleSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Google Sans";
  src: url("GoogleSans-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}*/
@font-face {
  font-family: "Javatages";
  src: url("Javatages.ttf");
  font-weight: normal;
  font-style: normal;
}
