@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-BoldItalic.otf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-Light.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("GothamRounded-LightItalic.otf");
  font-weight: 400;
  font-style: italic;
}

