/* p {
  font-size: 15px !important;
}
@media (max-width: 640px) {
  h2,
  .uk-h2 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
  h3,
  .uk-h3 {
    font-size: 1.4rem;
    line-height: 1.4;
  }
} */

.uk-offcanvas-bar {
  width: 300px;
}
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    width: 370px;
  }
}
.md-radio-primary.md-radio > input[type="radio"]:checked + label:before {
  border: 2px solid var(--mdc-theme-primary) !important;
}
.md-radio-primary.md-radio > label::after {
  background: var(--mdc-theme-primary) !important;
}
.md-border-primary {
  border: 1px var(--mdc-theme-primary) solid !important;
}
.product-image-container {
  /* max-width:340px;  */
  /* max-height:340px; */
}
.product-image {
  max-height: 340px;
  object-fit: contain;
  /* height:auto;
  width:100%; */
}
.fade-in-section {
  opacity: 0;
  /* transform: translateX(20vh); */
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  transition-delay: 0.5s;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
